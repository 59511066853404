export class MessageRenderer {
    constructor(chatWidget) {
      this.chatWidget = chatWidget;
    }
  
    render(container, messages, isTyping) {
      container.innerHTML = messages.map(message => `
        <div class="message ${message.isUser ? 'user-message' : 'bot-message'}">
          <div class="message-content-wrapper">
            <div class="message-content">${message.text}</div>
          </div>
        </div>
      `).join('');
  
      if (isTyping) {
        container.innerHTML += `
          <div class="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        `;
      }
    }
  }