export class FrequentQuestions {
    constructor(chatWidget) {
      this.chatWidget = chatWidget;
      this.questions = [
        "Por favor muestra una tabla con los datos principales de las sucursales registradas.",
        "¿Cuáles son los servicios disponibles en PARE?",
        "¿Cómo puedo contactar a soporte técnico?"
      ];
    }
  
    render(container) {
      container.innerHTML = `
        <div class="frequent-questions">
          <h3>Preguntas Frecuentes</h3>
          <ul>
            ${this.questions.map(question => 
              `<li data-question="${question}">${question}</li>`
            ).join('')}
          </ul>
        </div>
      `;
  
      // Agregar listeners
      const questions = container.querySelectorAll('li');
      questions.forEach(q => {
        q.addEventListener('click', () => {
          this.chatWidget.sendMessage(q.dataset.question);
        });
      });
    }
  }