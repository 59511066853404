import { reqtsApiForm } from "./request-api";

// api-service.js - Maneja todas las llamadas a la API
export class ApiService {
    async sendMessage(message) {
      return reqtsApiForm('chat', true, 'POST', { message });
    }
  
    async getChatHistory() {
      return reqtsApiForm('chat/history', true, 'POST', {});
    }
  
   
    getToken() {
      return localStorage.getItem('token');
    }
  }