export class Dependencies {
    constructor() {
      // Objeto que contiene las dependencias CSS y JS a cargar
      this.dependencies = {
        css: [
          {
            name: 'remixicon',
            url: 'https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css'
          },
          // Aquí puedes agregar más CSS
        ],
        js: [
          // Aquí puedes agregar librerías JS
        ]
      };
    }

    // Método para cargar todas las dependencias CSS y JS
    async loadAll() {
      try {
        // Cargar archivos CSS
        const cssPromises = this.dependencies.css.map(lib => 
          this.loadResource(lib.url, lib.name, 'css')
        );
        // Cargar archivos JS
        const jsPromises = this.dependencies.js.map(lib => 
          this.loadResource(lib.url, lib.name, 'js')
        );
        // Esperar a que todas las dependencias se carguen
        await Promise.all([...cssPromises, ...jsPromises]);
       // console.log('Dependencias cargadas correctamente');
      } catch (error) {
        console.error('Error cargando dependencias:', error);
      }
    }
    // Método para cargar un recurso (CSS o JS) dinámicamente
    loadResource(url, name, type = 'css') {
      return new Promise((resolve, reject) => {
        const existingResource = document.querySelector(
          type === 'css' 
            ? `link[href*="${name}"]`// Verifica si el CSS ya está en la página
            : `script[src*="${name}"]` // Verifica si el JS ya está en la página
        );
  
        if (existingResource) {
          resolve(); // Si ya está cargado, resolver la promesa inmediatamente
          return;
        }
        // Crear el elemento HTML correspondiente (link para CSS, script para JS)
        const element = type === 'css'
          ? document.createElement('link')
          : document.createElement('script');
  
        if (type === 'css') {
          element.rel = 'stylesheet';
          element.href = url;
        } else {
          element.src = url;
          element.async = true; // Carga asíncrona para mejorar el rendimiento
        }
        // Resolver la promesa cuando se cargue el recurso
        element.onload = () => resolve();
        element.onerror = () => reject(new Error(`Error cargando ${name}`));
        // Agregar el elemento al head del documento
        document.head.appendChild(element);
      });
    }
  
    // Método para agregar nuevas dependencias dinámicamente
    addDependency(name, url, type = 'css') {
      this.dependencies[type].push({ name, url });
    }
  }