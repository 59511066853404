import { FrequentQuestions } from './components/frequent-question';
import { MessageRenderer } from './components/MessageRenderer';
import './styles/chat-widget.css';
import corMiniImage from './images/cormini.png';
import { Dependencies } from './dependencies/dependencies';
import { ApiService } from './services/api-service';

class ChatWidget {

  constructor(containerId) {
    if (!containerId) {
      throw new Error('Se requiere un ID de contenedor');
    }
    // Cargar dependencias y luego inicializar
    this.dependencies = new Dependencies();
    this.apiService=new ApiService()

    this.dependencies.loadAll().then(() => {
      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => {
          this.initialize(containerId);
        });
      } else {
        this.initialize(containerId);
      }
    })

  }

  initialize(containerId) {
    // Obtener el contenedor donde se mostrará el widget
    this.container = document.getElementById(containerId);
    this.isOpen = false;

    if (!this.container) {
      console.error(`No se encontró el contenedor con ID: ${containerId}`);
      return;
    }
    // Variables para manejar el estado del chat
    this.messages = [];
    this.isTyping = false;
    this.isLoading = true;
    this.initialized = false;

    // Inicializar componentes de preguntas frecuentes y renderizado de mensajes
    this.frequentQuestions = new FrequentQuestions(this);
    this.messageRenderer = new MessageRenderer(this);

    // Inicializar el botón de alternar el chat
    this.initToggleButton();
  }

  initToggleButton() {
    // Definir la estructura del chat en el contenedor
    this.container.innerHTML = `
      <div class="chat-widget-wrapper">
        <div class="chat-toogle-media">
          <i class="ri-wechat-2-line"></i>
        </div>
        <div class="chat-toogle-content " style="display: none;">
          <div class="chat-boot-container">
            ${this.renderHeader()}
            <div class="messages-container" id="messages-container">
              ${this.renderLoading()}
            </div>
            ${this.renderForm()}
          </div>
        </div>
      </div>
    `;
    // Agregar evento de clic al botón para abrir/cerrar el chat
    const toggleButton = this.container.querySelector('.chat-toogle-media');
    toggleButton.addEventListener('click', () => this.toggleChat());
  }

  toggleChat() {
    this.isOpen = !this.isOpen;
    const chatContent = this.container.querySelector('.chat-toogle-content ');
    const toggleButton = this.container.querySelector('.chat-toogle-media i');

    if (this.isOpen) {
      chatContent.style.display = 'block';
      toggleButton.className = 'ri-close-line';

      // Si el chat no ha sido inicializado, configurar eventos y cargar historial
      if (!this.initialized) {
        this.initialized = true;
        this.setupListeners();
        this.loadChatHistory();
      }
    } else {
      chatContent.style.display = 'none';
      toggleButton.className = 'ri-wechat-2-line';
    }
  }

  renderHeader() {
    // Retorna el HTML del encabezado del chat
    return `
      <div class="chat-header">
        <div>
          <img src="${corMiniImage}" class="icon-header" alt="Logo Corbot" />
        </div>
        <div>
          <span class="title-header">Cor-IA</span>
        </div>
      </div>
    `;
  }

  renderLoading() {
    // Retorna el HTML de la animación de carga
    return `
      <div class="loading-container">
        <div class="loading-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <span class="loading-text">Cargando mensajes</span>
      </div>
    `;
  }

  renderForm() {
    // Retorna el HTML del formulario de entrada de mensajes
    return `
      <form class="message-input" id="message-form">
        <input
          type="text"
          placeholder="Escribe tu mensaje..."
          class="message-input-field"
          id="message-input"
        />
        <button type="submit" class="send-button" disabled>
          <i class="ri-send-plane-fill"></i>
        </button>
      </form>
    `;
  }

  setupListeners() {
    // Agregar eventos para manejar la entrada de mensajes y envío
    const form = document.getElementById('message-form');
    const input = document.getElementById('message-input');
    const button = form.querySelector('button');

    // Habilitar o deshabilitar el botón de enviar según el contenido del input
    input.addEventListener('input', (e) => {
      button.disabled = !e.target.value.trim() || this.isTyping || this.isLoading;
    });

    // Manejar el envío del mensaje
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const message = input.value;
      if (message.trim()) {
        this.sendMessage(message);
        input.value = '';
        button.disabled = true;
      }
    });
  }

  async loadChatHistory() {
    // Cargar historial de mensajes desde el servicio API
    try {
      const result = await this.apiService.getChatHistory();
      if (result.status) {
        this.messages = result.data.map(msg => ({
          id: msg.id,
          text: msg.message,
          isUser: msg.role === 'user',
          status: "sent",
          timestamp: new Date(msg.created_at)
        }));
      }
    } catch (error) {
      console.error("Error cargando historial:", error);
    } finally {
      this.isLoading = false;
      this.updateView();
    }
  }
  

  updateView() {
     // Actualizar la vista del chat con los mensajes cargados
    const messagesContainer = document.getElementById('messages-container');
    if (this.messages.length === 0) {
      this.frequentQuestions.render(messagesContainer);
    } else {
      this.messageRenderer.render(messagesContainer, this.messages, this.isTyping);
    }
    this.scrollToBottom();
  }

  scrollToBottom() {
    // Hacer scroll automático al final de la lista de mensajes
    const messagesContainer = document.getElementById('messages-container');
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  }

  async sendMessage(message) {
    // Crear un nuevo mensaje del usuario
    const newMessage = {
      id: Date.now().toString(),
      text: message,
      isUser: true,
      status: "sent",
      timestamp: new Date()
    };

    this.messages.push(newMessage);
    this.updateView();
    this.isTyping = true;
    this.updateView();

    try {
      // Enviar el mensaje al servidor
      const result=await this.apiService.sendMessage(message)
      if (result.status) {
        const botResponse = {
          id: (Date.now() + 1).toString(),
          text: result.data,
          isUser: false,
          status: "sent",
          timestamp: new Date()
        };
        this.messages.push(botResponse);
      } else {
        const botResponse = {
          id: (Date.now() + 1).toString(),
          text: '<p>Error, Intenta nuevamente</p>',
          isUser: false,
          status: "sent",
          timestamp: new Date()
        };
        this.messages.push(botResponse);
      }

    } catch (error) {
      // Si hay un error, actualizar el estado del mensaje
      this.messages = this.messages.map(msg =>
        msg.id === newMessage.id ? { ...msg, status: "error" } : msg
      );

    }finally{
      this.isTyping = false;
      this.updateView();
    }
  }
}

// Hacer disponible el widget en el objeto global `window`
if (typeof window !== 'undefined') {
  window.ChatWidget = ChatWidget;
}

export default ChatWidget;