import { API } from "../config/enviroment";


function getToken() {
    return localStorage.getItem('token');
}

// funcion para hacer peticiones
export const reqtsApiForm = async (endpoint, auth, method, data) => {
    try {
        const response = await fetch(`${API}/${endpoint}`, {

            method,
            headers: {
                'Content-Type': 'application/json',
                ...(auth ? { 'Authorization': `Bearer ${getToken()}` } : {})
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }
    catch (error) {
        return {status:false, data:"Hubo un error al enviar la peticion"  }
    }
}
